import React, { useState } from "react";
import { Button, FileObjectType, Flex, Text, toast } from "@jsq/ds/core";
import { useCreateDocumentsMutation } from "../../graphql/supergraph.index";
import useUploadFilesToS3 from "../../hooks/uploadFilesToS3/useUploadFilesToS3";
import { getErrorMessage } from "../../utils/error_parser";
import { clientContext } from "../../utils/graphql_utils";
import { S3DocumentInput } from "../../utils/types";
import { SingleFileUpload } from "../common/SingleFileUpload";
import {
  buttonContainerStyles,
  descriptionStyle,
  identificationWrapper,
} from "./style_vanilla.css";
import { IndividualLandingProps } from "./types";

// TODO: Remove this, temp change
export const TESTING_ORG_NAME = "Testing Individual Identification";

/**
 * This component provides an interface for users to upload identification documents
 * as required by regulatory requirements. It handles file validation, upload to S3,
 * and document registration in the system.
 *
 * The component will:
 * 1. Allow a user to upload identification documents
 * 3. Upload the document to S3
 * 4. Register the document in the system
 * // TODO: Add profile name to be received from the params
 * // TODO: Show the already added
 * // TODO: Add back click handler
 * // TODO: Add error handling
 * // TODO: Navigation on successfull upload
 */
export const IndividualLandingScreen: React.FC<IndividualLandingProps> = ({ caseId }) => {
  const [document, setDocument] = useState<FileObjectType | null>(null);
  const { uploadFilesToS3, loading } = useUploadFilesToS3();
  const [createDocuments] = useCreateDocumentsMutation({
    context: clientContext(),
  });

  // TODO: Get the caseId from the params / props
  const orgName = TESTING_ORG_NAME;

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!document) {
      toast.error("Please select a file before submitting.");
      return;
    }
    const fileObject: FileObjectType["file"] = document.file;
    handleUploadDocs(fileObject)
      .then(() => {
        toast.success("uploaded successfully");
      })
      .catch((e) => {
        toast.error(getErrorMessage(e));
      });
  };

  /**
   * Handles document upload and registration
   * @param values Form values containing the document to upload
   */
  const handleUploadDocs = async (file: FileObjectType["file"]) => {
    const uploadResponse = await uploadFilesToS3([file]);
    const s3ObjectKeys = uploadResponse.map((s3File): S3DocumentInput => {
      return {
        objectKey: s3File.objectKey,
        name: file.name,
        size: file.size,
        type: file.type,
      };
    });

    const documentInputs = s3ObjectKeys.map((s3ObjectKey) => ({
      contentType: s3ObjectKey.type,
      fileName: s3ObjectKey.name || "",
      s3Key: s3ObjectKey.objectKey || "",
    }));
    await createDocuments({
      variables: { caseId: parseInt(caseId), documents: documentInputs },
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Flex flexDirection="column" className={identificationWrapper}>
        <Text variant="h3">Identification</Text>
        <Flex flexDirection="column" gap="20px">
          <Text
            variant="regular-md"
            className={descriptionStyle}
          >{`Due to regulatory requirements, an identification document for ${orgName}
                  is required. You can skip this step for now if you don't have these
                  documents available. You will need to return and upload any required documents
                  before signing this subscription.`}</Text>
          <SingleFileUpload
            title="Identification document"
            description="Government-issued identification containing a photograph and signature. Examples include: Passport, driver's license, or government-issued health card."
            acceptedFileFormats={[
              "image/*",
              ".pdf",
              ".doc",
              ".docx",
              ".xlsx",
              ".xls",
              ".csv",
              ".zip",
            ]}
            initialFile={null}
            onFileSelected={(file) => {
              setDocument(file);
            }}
            onFileDeleted={() => {
              setDocument(null);
            }}
            required
          />
        </Flex>
        <Flex
          flexDirection="column"
          gap="10px"
          alignItems="flex-end"
          className={buttonContainerStyles}
        >
          <Flex flexDirection="row" gap="10px" justifyContent="flex-end">
            <Button
              variant="neutral"
              type="button"
              onClick={() => {
                /* TODO: Back button clicked */
              }}
              disabled={loading}
            >
              Back
            </Button>
            <Button type="submit" isLoading={loading}>
              Complete
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </form>
  );
};
