import { ApolloError } from "@apollo/client";

// https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
type ErrorWithMessage = {
  message: string;
};

export function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
}

export function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) {
    return maybeError;
  }

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback for stringify maybeError (with circular references for example)
    return new Error(String(maybeError));
  }
}

/**
 * Parse message from an error object which may or may not be an instanceof Error
 * @param error
 * @returns error message
 */
export function getErrorMessage(error: unknown): string {
  if (error instanceof ApolloError) {
    return error.graphQLErrors.length > 0 ? error.graphQLErrors[0].message : error.message;
  }

  return toErrorWithMessage(error).message;
}
