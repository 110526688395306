type UrlGeneratorInputType = {
  name: string;
  size: number;
  type: string;
};

export type GeneratedS3UrlObjectType = {
  objectKey: string;
  s3Url: string;
};

export type GenerateS3UrlResultType = {
  generatePresignedUrls: {
    presignedUrlObjs: GeneratedS3UrlObjectType[];
  };
};

export type S3FileUploadType = {
  generatedUrlObj: GeneratedS3UrlObjectType;
  file: File;
};

export type UploadResponseType = {
  response: Response;
  objectKey: string;
  s3Url: string;
  file: File;
};

type S3DocumentInputType = {
  objectKey: string;
  name: string;
  size: number;
  type: string;
};

export const getS3UploadErrorsAsString = (errorResponses: Response[]): string => {
  if (errorResponses.length === 0) {
    return "";
  }

  const errorStr = errorResponses
    .map((e) => `url: ${e.url} status: ${e.status} statusText: ${e.statusText}`)
    .join(", ");
  return `Error while trying to upload files to S3. Errors:  ${errorStr}`;
};

export const getS3DocumentInputObj = (objectKey: string, file: File): S3DocumentInputType => {
  const { name, size, type } = file;
  return {
    objectKey,
    name,
    size,
    type,
  };
};

export const getUrlGeneratorInput = (files: File[]): UrlGeneratorInputType[] => {
  return files.map((file) => {
    return {
      name: file.name,
      size: file.size,
      type: file.type,
    };
  });
};

/**
 * Handles uploading individual file. Should not be a public API and only called from uploadFilesToS3().
 * Note: The file will always attempt to upload once regardless of retries count.
 * @param fileToUpload - The file to upload
 * @param retries - The number of retries. This is in addition to the initial upload.
 *
 */
export const uploadFile = async (
  fileToUpload: S3FileUploadType,
  retries: number,
): Promise<UploadResponseType> => {
  let internalRetries = retries;
  let result: UploadResponseType;

  do {
    result = await fetch(fileToUpload.generatedUrlObj.s3Url, {
      method: "PUT",
      body: fileToUpload.file,
    }).then((response) => {
      return {
        response,
        objectKey: fileToUpload.generatedUrlObj.objectKey,
        s3Url: fileToUpload.generatedUrlObj.s3Url,
        file: fileToUpload.file,
      };
    });

    if (result.response.status === 200) {
      break;
    }
  } while (internalRetries-- > 0);
  return result;
};

/**
 * Makes an async call to upload a file to an S3 bucket at the URL stored within
 * the generatedUrlObj. We generate this URL by using a GQL mutation: generatePresignedUrls.
 * @param filesToUpload - S3FileUploadType which the file, url, and objectKey stored in it
 * @param retryCount - the number of times to retry. Will always attempt 1 upload first.
 *
 * Returns: A promise when resolved contains a response and objectKey for the file saved.
 */
export const uploadFilesToS3 = async (
  filesToUpload: S3FileUploadType[],
  retryCount = 3,
): Promise<UploadResponseType[]> => {
  const results = await Promise.all(filesToUpload.map((f) => uploadFile(f, retryCount)));
  if (results.some((r) => r.response.status !== 200)) {
    return Promise.reject(results);
  }
  return results;
};
