/**
 * Provides the federation endpoint context for the apollo client.
 * @param context
 * @returns
 */
export const clientContext = (
  context: { [key: string]: unknown } = {},
): { [key: string]: unknown } => ({
  ...context,
  clientName: "federation",
});

/**
 * Takes global ID and returns a DB ID
 * @param globalId
 */
export const toDbId = (globalId: string): number => {
  return parseInt(atob(globalId).split(":")[1], 10);
};

/**
 * Takes an object type and database ID and returns a global ID
 *
 * @param objectType
 * @param dbId
 */
export function toGlobalId(objectType: string, dbId: number | string): string {
  return btoa([encodeURIComponent(objectType), dbId].join(":"));
}
