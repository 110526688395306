import { useState, useCallback } from "react";
import { useComplianceGeneratePresignedUrlsMutation } from "../../graphql/supergraph.index";
import { clientContext } from "../../utils/graphql_utils";
import { uploadFilesToS3, getUrlGeneratorInput } from "../../utils/s3UploadClient";
/**
 * A react hook which returns a function that takes in a list of files and uploads them to
 * the S3 bucket.
 * The function returns a Promise object which will resolve to an array of
 * UploadResponseType instances. Each contain the Response and objectKey for the uploaded file.
 * The objectKey can be used to reference the file in the S3 bucket.
 */
export default function useUploadFilesToS3() {
  const [loading, setLoading] = useState(false);
  // Uploads to S3 bucket require a pre-signed URL. This mutation will take care of
  // generating the pre-signed URL and generating an object key to reference the file.
  const [getPresignedUrlsForUpload] = useComplianceGeneratePresignedUrlsMutation({
    context: clientContext(),
  });

  const uploadFiles = useCallback(
    (files: File[], retryCount = 3) => {
      setLoading(true);
      const urlGeneratorInput = getUrlGeneratorInput(files);
      return getPresignedUrlsForUpload({
        context: clientContext(),
        variables: { urlGeneratorInput },
      })
        .then((urlResponse) => {
          const presignedObjs = urlResponse.data?.complianceGeneratePresignedUrls || [];
          const filesToUpload = files.map((file, index) => {
            return {
              generatedUrlObj: presignedObjs[index],
              file,
            };
          });
          return uploadFilesToS3(filesToUpload, retryCount);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [getPresignedUrlsForUpload],
  );

  return { uploadFilesToS3: uploadFiles, loading };
}
