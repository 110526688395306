import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { JointLandingScreenProps } from "./types";

export const ProfileActionOverviewScreen: React.FC<JointLandingScreenProps> = ({
  caseId,
  caseResult,
  basePath,
}) => {
  const { profileId } = useParams<{ profileId: string }>();
  const history = useHistory();
  const investingProfileId = caseResult?.complianceCasesByObjectId[0].investingProfile?.id;
  const investingProfileAccountType =
    caseResult?.complianceCasesByObjectId[0].investingProfile?.accountType || "";

  if (
    investingProfileId === parseInt(profileId) &&
    ["individual", "joint", "joint_other", "tic"].includes(investingProfileAccountType)
  ) {
    // This shouldn't normally happen, but if user changes the account type and clicks on a cached URL,
    // redirect to the correct page.
    history.push(basePath);
  }

  return (
    <div>
      Action Overview for caseId: {caseId}, profileId: {profileId}
    </div>
  );
};
