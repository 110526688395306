import React from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { QueryLoader } from "@jsq/ds/core";
import {
  AccountTypeEnum,
  useGetComplianceCasesByObjectIdQuery,
} from "../../graphql/supergraph.index";
import { IndividualLandingScreen } from "../IndividualLandingScreen";
import { JointLandingScreen } from "../JointLandingScreen";
import { ProfileActionOverviewScreen } from "../ProfileActionOverviewScreen";
import { SubscriptionComplianceProps, ShowIndividualAndJointRedirectOtherProps } from "./types";

const ShowIndividualAndJointRedirectOther: React.FC<
  ShowIndividualAndJointRedirectOtherProps
> = ({ caseData, basePath }) => {
  const history = useHistory();
  const accountType = caseData?.complianceCasesByObjectId[0].investingProfile
    ?.accountType as AccountTypeEnum;
  const caseId = caseData?.complianceCasesByObjectId[0].id?.toString() || "";
  if (accountType === "individual") {
    return <IndividualLandingScreen caseId={caseId} />;
  }
  if (["joint", "joint_other", "tic"].includes(accountType)) {
    return <JointLandingScreen caseId={caseId} caseResult={caseData} />;
  }
  history.push(
    `${basePath}/overview/${caseData?.complianceCasesByObjectId[0].investingProfile?.id}`,
  );
  return null;
};

/**
 * SubscriptionCompliance root component
 *
 * A router component that provides routes to all compliance components
 */
export const SubscriptionCompliance: React.FC<SubscriptionComplianceProps> = ({
  basePath,
  arenaId,
  prospectId,
}) => {
  const result = useGetComplianceCasesByObjectIdQuery({
    variables: {
      arenaId,
      externalObject: {
        objectTypeEnum: "prospect",
        objectId: prospectId?.toString() || "",
        service: "main",
      },
    },
  });

  const caseId = result.data?.complianceCasesByObjectId[0].id?.toString() || "";

  return (
    <QueryLoader result={result}>
      <Switch>
        <Route path={`${basePath}/overview/:profileId`} exact>
          <ProfileActionOverviewScreen
            basePath={basePath}
            caseId={caseId}
            caseResult={result.data}
          />
        </Route>
        <Route path={basePath} exact>
          <ShowIndividualAndJointRedirectOther caseData={result.data} basePath={basePath} />
        </Route>
      </Switch>
    </QueryLoader>
  );
};
