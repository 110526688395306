// lib/components/RevealView/index.tsx
import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { LoadingIndicator, ErrorMessage } from "@jsq/ds/core";
import { addError } from "@jsq/observability";
import { useDashboardHub } from "../../contexts/DashboardHubContext";
import { Viewer } from "../../contexts/types";
import * as styles from "./styles_vanilla.css";
import type { RevealViewProps } from "./types";
export const RevealView: React.FC<RevealViewProps> = ({
  dashboard,
  height = 800,
  width = "100%",
  showFilters = false,
  canEdit = false,
  canSave = false,
  type,
  crosshairs = false,
  className,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const viewerRef = useRef<Viewer>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { isLoaded } = useDashboardHub();

  // Initialize the RevealView component
  const initializeDashboard = (dash: unknown) => {
    if (!window.$ || !window.$.ig) return;
    const viewer = new window.$.ig.RevealView("#revealView");
    viewerRef.current = viewer;
    // Disable export and save as features
    viewer.showExportImage = false;
    viewer.showExportToPDF = false;
    viewer.showExportToExcel = false;
    viewer.showExportToPowerPoint = false;
    viewer.canSaveAs = false;
    viewer.showRefresh = false;
    viewer.dashboard = dash;
    viewer.singleVisualizationMode = type === "view";
    viewer.showFilters = showFilters;
    viewer.canEdit = canEdit;
    viewer.canSave = canSave;
    viewer.crosshairsEnabled = crosshairs;
    // Disable menu if canEdit is false
    viewer.showMenu = canEdit || false;
    setIsLoading(false);
  };

  // Handle errors when loading the dashboard
  const handleError = (err: Error) => {
    setError(err.message);
    addError(err.message);
    setIsLoading(false);
  };

  // Load the dashboard once
  useEffect(() => {
    let mounted = true;

    const loadDashboard = () => {
      try {
        if (!isLoaded) return;

        window.$?.ig?.RVDashboard.loadDashboard(
          dashboard,
          (dash) => {
            if (!mounted) return;
            void initializeDashboard(dash);
          },
          (error) => {
            if (!mounted) return;
            handleError(new Error(`Failed to load dashboard: ${error.message}`));
          },
        );
      } catch (err) {
        if (!mounted) return;
        handleError(err instanceof Error ? err : new Error("Failed to initialize Reveal"));
      }
    };

    loadDashboard();
    return () => {
      mounted = false;
    };
  }, [dashboard, isLoaded]);

  useEffect(() => {
    const viewer = viewerRef.current;
    if (!viewer) return;
    viewer.singleVisualizationMode = type === "view";
    viewer.showFilters = showFilters;
    viewer.canEdit = canEdit;
    viewer.canSave = canSave;
    viewer.crosshairsEnabled = crosshairs;
  }, [showFilters, canEdit, canSave, type, crosshairs]);

  return (
    <>
      {isLoading && <LoadingIndicator />}
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <div
        id="revealView"
        ref={containerRef}
        className={classnames(styles.revealView, className)}
        style={{
          display: isLoading || error ? "none" : "block",
          height,
          width,
        }}
      />
    </>
  );
};

export default RevealView;
