// The base route for the DashboardHub API service that Reveal SDK uses
export const BASE_ROUTE = "services/dashboard-hub/reveal-sdk/api/v1";

// The jQuery script that is used to load the Reveal SDK
export const JQUERY_SCRIPT = {
  name: "jquery",
  src: "https://code.jquery.com/jquery-3.7.1.min.js",
  verify: () => typeof window.$ !== "undefined",
};

// The Reveal SDK script that is used to load the Reveal SDK
export const REVEAL_SCRIPT = {
  name: "reveal",
  src: "https://dl.revealbi.io/reveal/libs/1.7.3/infragistics.reveal.js",
  verify: () => typeof window.$?.ig !== "undefined",
};

// Theme overrides for the Reveal SDK
export const JSQ_THEME_CONFIG = {
  fontColor: "#000000",
  accentColor: "rgb(0, 97, 78)",
  useRoundedCorners: true,
  chartColors: ["#16A0AC"],
  visualizationMargin: 5,
  dashboardBackgroundColor: "#ffffff",
  regularFont: "Moderat",
  mediumFont: "Moderat",
  boldFont: "Moderat",
};
